<script>
export default {
  name: 'i-frame',
  // render(h) {
  //   return h('iframe', {
  //   	on: { load: this.renderChildren }
  //   })
  // },
  // beforeUpdate() {
  //   this.iApp.children = Object.freeze(this.$slots.default)
  // },
  // methods: {
  //   renderChildren() {
  //     const children = this.$slots.default
  //     const el = document.createElement('DIV')
  //     this.appendChild(el)
  //     const iApp = new Vue({
  //     	name: 'iApp',
  //       data: { children: Object.freeze(children) },
  //       render(h) {
  //         return h('div', this.children)
  //       },
  //     })
  //     iApp.$mount(el)
  //     this.iApp = iApp
  //   }
  // }
}
</script>

<template>
  <div class="full-page" :class="{'nav-open': $sidebar.showSidebar}">
    <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
      <div class="md-toolbar-row md-offset">
        <div class="md-toolbar-section-start">
          <a href="/" class="simple-text logo-mini" target="_blank">
            <div class="login-logo-img">
              <img :src="logo">
            </div>
          </a>
          <h4 class="logo-header">ANKIETER</h4>
        </div>
        <!-- <div class="md-toolbar-section-end">
          <md-button class="md-just-icon md-simple md-round md-toolbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>
        </div> -->
      </div>
    </md-toolbar>
    <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
      <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
        <div class="container md-offset">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container">
              <div class="copyright">
                &copy; {{ new Date().getFullYear() }} <a href="https://neurohm.com" target="_blank">Neurohm</a>, made by <i class="fa fa-heart heart"></i> Softdeco
              </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import {ZoomCenterTransition} from 'vue2-transitions'

  export default {
    components: {
      ZoomCenterTransition
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      },
      logo: {
        type: String,
        default: './img/neurohm.png'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    data() {
      return {
        responsive: false,
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 300,
        year: new Date().getFullYear(),
      }
    },
    computed: {
      setBgImage(){
        let images = {
          Pricing: './img/bg-pricing.jpg',
          Login: './img/card-3.jpg',
          Register: './img/register.jpg',
          Lock: './img/lock.jpg'
        };
        return {
          backgroundImage: `url(${images[this.$route.name]})`
        }
      },
      setPageClass(){
        return `${this.$route.name}-page`.toLowerCase();
      }
    },
    methods: {
      toggleSidebarPage () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      linkClick() {
        if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
          this.$sidebar.displaySidebar(false)
        }
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
        this.showMenu = !this.showMenu
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        this.showMenu = false
      },
      onResponsiveInverted () {
        if (window.innerWidth < 991){
          this.responsive = true
        } else {
          this.responsive = false
        }
      }
    },
    mounted() {
      this.onResponsiveInverted();
      window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy() {
      this.closeMenu()
      window.removeEventListener('resize', this.onResponsiveInverted)
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next()
        }, this.menuTransitionDuration)
      } else {
        next()
      }
    }
  }
</script>
<style lang="scss" scoped>
  $scaleSize: .1;
  $zoomOutStart: .7;
  $zoomOutEnd: .46;
  @keyframes zoomIn8 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
      opacity: 1;
    }
  }
  .wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
  }
  @keyframes zoomOut8 {
    from {
      opacity: 1;
      transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
    }
    to {
      opacity: 0;
      transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);

    }
  }
  .wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
  }
  .login-logo-img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    img{
      width: 100%;
      vertical-align: unset;
      position: relative;
    }
  }
  .logo-header {
    margin-left: 10px;
    color: #fff;
  }
  .footer .container {
    text-align: center;
  }
  .copyright {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
</style>

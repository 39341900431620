<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar"/>
    </div>
    <div class="user-info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
           <span v-if="$route.meta.rtlActive">
             {{rtlTitle}}
             <b class="caret"></b>
          </span>
           <span v-else>
             {{userName}}
             <b class="caret"></b>
          </span>
      </a>

        <collapse-transition>
            <div v-show="!isClosed">
              <ul class="nav" >
                <slot>
                  <li>
                    <a v-if="$route.meta.rtlActive" :href="'/administrators/edit/' + adminId">
                      <span class="sidebar-mini">و</span>
                      <span class="sidebar-normal">إعدادات</span>
                    </a>
                    <a v-else :href="'/administrators/edit/' + adminId">
                      <span class="sidebar-mini">S</span>
                      <span class="sidebar-normal">Settings</span>
                    </a>
                  </li>
                </slot>
              </ul>
          </div>
        </collapse-transition>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';

  export default {
    components: {
      CollapseTransition
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      rtlTitle: {
        type: String,
        default: 'تانيا أندرو'
      }
    },
    data() {
      return {
        adminId: localStorage.getItem('admin_id'),
        avatar: localStorage.getItem('avatar'),
        isClosed: true,
        userName: localStorage.getItem('user')
      }
    },
    methods: {
      clicked: function(e) {
        e.preventDefault()
      },
      toggleMenu: function() {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <h4>Server performance</h4>
      </div>
      <div class="md-layout-item md-size-50 text-right">
        <md-button class="md-just-icon md-simple md-success refresh-server-performance" @click.native="loadServerPerformance()">
          <md-icon>autorenew</md-icon>
        </md-button>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card :header-color="getServerPerformanceHeaderColor('cpu1')">
          <template slot="header">
            <div class="card-icon">
              <md-icon>memory</md-icon>
            </div>
            <p class="category">CPU average load</p>
            <h3 v-if="load.cpu" class="title" :class="[{'text-danger': load.cpu[0] > 80}]"><animated-number :value="load.cpu[0]"></animated-number>%</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              Last 1 minute
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card :header-color="getServerPerformanceHeaderColor('cpu5')">
          <template slot="header">
            <div class="card-icon">
              <md-icon>memory</md-icon>
            </div>
            <p class="category">CPU average load</p>
            <h3 v-if="load.cpu" class="title" :class="[{'text-danger': load.cpu[1] > 80}]"><animated-number :value="load.cpu[1]"></animated-number>%</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              Last 5 minutes
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card :header-color="getServerPerformanceHeaderColor('cpu15')">
          <template slot="header">
            <div class="card-icon">
              <md-icon>memory</md-icon>
            </div>
            <p class="category">CPU average load</p>
            <h3 v-if="load.cpu" class="title" :class="[{'text-danger': load.cpu[2] > 80}]"><animated-number :value="load.cpu[2]"></animated-number>%</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              Last 15 minutes
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card :header-color="getServerPerformanceHeaderColor('ram')">
          <template slot="header">
            <div class="card-icon">
              <md-icon>developer_board</md-icon>
            </div>
            <p class="category">RAM usage</p>
            <h3 v-if="load.ram" class="title" :class="[{'text-danger': load.ram > 80}]"><animated-number :value="load.ram"></animated-number>%</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              Current
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import {StatsCard, AnimatedNumber} from '@/components'

export default {
  components: {
    StatsCard,
    AnimatedNumber
  },
  data () {
    return {
      load: {}
    }
  },
  created () {
    this.loadServerPerformance()
  },
  methods: {
    loadServerPerformance () {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/server')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = () => {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response) {
          self.load = {}
          setTimeout(() => {self.load = response.response}, 100)
        }
      }
      xhr.send()
    },
    getServerPerformanceHeaderColor (what) {
      if (what === 'cpu1') return (this.load.cpu && this.load.cpu[0] >= 80) ? 'danger' : 'green'
      else if (what === 'cpu5') return (this.load.cpu && this.load.cpu[1] >= 80) ? 'danger' : 'green'
      else if (what === 'cpu15') return (this.load.cpu && this.load.cpu[2] >= 80) ? 'danger' : 'green'
      else if (what === 'ram') return (this.load.ram && this.load.ram >= 80) ? 'danger' : 'green'
    }
  }
}
</script>

<style scoped>
.refresh-server-performance {
  position: relative;
  top: 10px;
  float: right;
}
</style>

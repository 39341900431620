<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <md-field>
        <label>Search</label>
        <md-input v-model="search" type="text"></md-input>
      </md-field>
    </li>
    <md-list-item to="/login">
      <i class="material-icons" @click="logout">power_settings_new</i>
    </md-list-item>
  </ul>
</template>
<script>
export default {
  data () {
    return {
      search: null,
      selectedEmployee: null,
      employees: [
        'Rafał Janota',
        'Marcin Żyszkiewicz',
        'Kamil Dudek'
      ]
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    }
  }
}
</script>

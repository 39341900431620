<template>
  <footer class="footer">
    <div class="container">
        <nav>
            <ul>

            </ul>
        </nav>
        <div class="copyright text-center">
          &copy; {{ new Date().getFullYear() }} <a href="https://neurohm.com" target="_blank">Neurohm</a>, made by <i class="fa fa-heart heart"></i> Softdeco
        </div>
    </div>
  </footer>

</template>
<script>
export default {}

</script>
<style>

</style>


import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardPlugin from './material-dashboard'
import VueClipboards from 'vue-clipboards'
import VueDisabled from 'vue-disabled'

// Plugins
import App from './App.vue'
import Chartist from 'chartist'

// router setup
import routes from './routes/routes'

// plugin setup
Vue.use(VueRouter)
Vue.use(DashboardPlugin)
Vue.use(VueClipboards)
Vue.use(VueDisabled)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkExactActiveClass: 'nav-item active'
});

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
  get () {
    return this.$root.Chartist
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  data: {
    Chartist: Chartist,
    apiUrl: process.env.VUE_APP_API_URL
  }
});

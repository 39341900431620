<template>
  <div class="loader-ring" v-if="$route.name !== 'Login' && isLoggedIn === false">
    <img :src="loader">
  </div>
  <router-view v-else></router-view>
</template>

<script>

export default {
  data() {
    return {
      isLoggedIn: false
    }
  },
  props: {
    loader: {
      type: String,
      default: './img/loader.gif'
    }
  },
  mounted() {
    this.checkLoggedIn()
  },
  updated() {
    this.checkLoggedIn()
  },
  methods: {
    isStats() {
      return window.location.href.includes('experiments/stats')
    },
    checkLoggedIn() {
      var token = localStorage.getItem('token')
      if (token || this.isStats()) {
        this.isLoggedIn = true
      } else {
        this.$router.push({name: 'Login'})
      }
    }
  }
}
</script>

<style lang="scss">
  .loader-ring {
    position: fixed;
    top: 50%;
    margin-top: -100px;
    left: 50%;
    margin-left: -100px;
  }
  .md-menu.md-select {
    overflow: hidden !important;
  }
</style>
